<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewQualificationsNSC" :disabled="!$auth.isLoggedIn()">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length != 1)||(!$auth.isLoggedIn())"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length == 0)||(!$auth.isLoggedIn())"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="scroll"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
      :class="gridData.length < 5 ? 'hide-native-scrollbar' : ''"
    />

    <a-modal
      width="100"
      v-model:visible="visibleQualificationsNSC"
      title="Квалификации НСК"
      @ok="handleOkQualificationsNSC"
    >
      <a-form ref="QualificationsNSCForm" :model="QualificationsNSCFormState">
        <a-form-item label="Наименование">
          <a-input
            v-model:value="QualificationsNSCFormState.QualificationsNSCTitle" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      <a-form-item
              label="Уровень Квалификации НСК"
            >
              <a-select v-model:value="QualificationsNSCFormState.nskQualificationsLevel" :disabled="!$auth.isLoggedIn()">
                <a-select-option
                  v-for="nskQualificationsLevel in NSKQualificationsLevels"
                  :key="nskQualificationsLevel.id"
                  v-bind:value="nskQualificationsLevel.id"
                >
                  {{ nskQualificationsLevel.title }}
                </a-select-option>
              </a-select>
      </a-form-item>
        <a-form-item label="URL">
          <a-input
            v-model:value="QualificationsNSCFormState.QualificationsNSCUrl" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "QualificationsNSC",
  props: {
    SampleProgramID: {
      type: Array
    },
    scroll: {
      type: Object,
      default () {
        return {
          x: 'calc(100%)',
          y: '100%'
        }
      }
    }
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID", width: 80 },
        //{ dataIndex: "spId", title: "SPID" },
        { dataIndex: "nskQualificationsLevel.title", title: "Уровень Квалификации НСК" },
        { dataIndex: "title", title: "Наименование" },
        { dataIndex: "url", title: "URL" },
      ],
      gridData: [],
      visibleQualificationsNSC: false,
      thisQualificationsNSC: [],
      QualificationsNSCFormState: {
        QualificationsNSCID: undefined,
        QualificationsNSCSPID: undefined,
        nskQualificationsLevel: undefined,
        QualificationsNSCTitle: undefined,
        QualificationsNSCUrl: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
      NSKQualificationsLevels : [],
    };
  },
  watch: {
    SampleProgramID: {
      handler() {
        this.reload();
      },
      function() {},

      immediate: true,
    },
  },
  methods: {
    async reload() {
      this.NSKQualificationsLevels = (await axios.get("NSKQualificationsLevel")).data;
      if (this.SampleProgramID == undefined) {
        this.gridData = (await axios.get("qualificationsnsc")).data;
      } else {
        if (this.SampleProgramID == -1) {
          this.gridData = [];
        } else {
          this.gridData = (
            await axios.get(
              `qualificationsnsc/slaveobject/${this.SampleProgramID}`
            )
          ).data;
        }
      }
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisQualificationsNSC = record;
          this.QualificationsNSCFormState.QualificationsNSCID = record.id;
          this.QualificationsNSCFormState.QualificationsNSCSPID = record.spId;
          this.QualificationsNSCFormState.nskQualificationsLevel = record.nskQualificationsLevel.id;
          this.QualificationsNSCFormState.QualificationsNSCTitle = record.title;
          this.QualificationsNSCFormState.QualificationsNSCUrl = record.url;
          console.log(this.thisQualificationsNSC);
          this.showModalQualificationsNSC();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalQualificationsNSC() {
      this.visibleQualificationsNSC = true;
    },
    clearForm() {
      this.QualificationsNSCFormState.QualificationsNSCID = undefined;
      this.QualificationsNSCFormState.QualificationsNSCSPID = undefined;
      this.QualificationsNSCFormState.nskQualificationsLevel = undefined;
      this.QualificationsNSCFormState.QualificationsNSCTitle = undefined;
      this.QualificationsNSCFormState.QualificationsNSCUrl = undefined;
    },
    showModalNewQualificationsNSC() {
      this.clearForm();
      if (this.SampleProgramID != undefined)
        this.QualificationsNSCFormState.QualificationsNSCSPID =
          this.SampleProgramID;
      this.visibleQualificationsNSC = true;
    },
    async handleOkQualificationsNSC() {
      console.log("qualificationsnsc.");
      this.visibleQualificationsNSC = false;
      if (
        this.QualificationsNSCFormState.QualificationsNSCTitle !== undefined
      ) {
        if (this.QualificationsNSCFormState.QualificationsNSCID == undefined) {
          try {
            await axios.post("qualificationsnsc", {
              title: this.QualificationsNSCFormState.QualificationsNSCTitle,
              nskQualificationsLevel: this.QualificationsNSCFormState.nskQualificationsLevel,
              spid: this.QualificationsNSCFormState.QualificationsNSCSPID,
              url: this.QualificationsNSCFormState.QualificationsNSCUrl,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        } else {
          console.log("qualificationsnsc");
          console.log({
            id: this.QualificationsNSCFormState.QualificationsNSCID,
            title: this.QualificationsNSCFormState.QualificationsNSCTitle,
          });
          try {
            await axios.put("qualificationsnsc", {
              id: this.QualificationsNSCFormState.QualificationsNSCID,
              spid: this.QualificationsNSCFormState.QualificationsNSCSPID,
              title: this.QualificationsNSCFormState.QualificationsNSCTitle,
              nskQualificationsLevel: this.QualificationsNSCFormState.nskQualificationsLevel,
              url: this.QualificationsNSCFormState.QualificationsNSCUrl,
            });
          } finally {
            this.clearForm();
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("qualificationsnsc", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.QualificationsNSCFormState.QualificationsNSCID = selectedRows[0].id;
      this.QualificationsNSCFormState.QualificationsNSCSPID =
        selectedRows[0].spId;
      this.QualificationsNSCFormState.QualificationsNSCTitle =
        selectedRows[0].title;
      this.QualificationsNSCFormState.nskQualificationsLevel =
        selectedRows[0].nskQualificationsLevel;
      this.QualificationsNSCFormState.QualificationsNSCUrl =
        selectedRows[0].url;
      this.showModalQualificationsNSC();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>
